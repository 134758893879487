import Vue from "vue";
import VueRouter from "vue-router";
import VueScrollTo from "vue-scrollto";
Vue.use(VueRouter);

const routes = [
     {
          path: "/",
          name: "HOME",
          component: () => import("../layouts/home_layout.vue"),
     },
     {
          path: "/",
          name: "DEFAULT_LAYOUT",
          component: () => import("../layouts/default_layout.vue"),
          children: [
               {
                    path: "/solutions",
                    name: "SERVICES",
                    component: () => import("../views/Services.vue"),
               }, 

               {
                    path: "/solutions/aiot",
                    name: "AIOT",
                    component: () => import("../views/services/aiot.vue"),
               },
               {
                    path: "/solutions/avs",
                    name: "AVS",
                    component: () => import("../views/services/avs.vue"),
               },
               {
                    path: "/solutions/cc",
                    name: "CC",
                    component: () => import("../views/services/cc.vue"),
               },
               {
                    path: "/solutions/ess",
                    name: "ESS",
                    component: () => import("../views/services/ess.vue"),
               },
               {
                    path: "/solutions/ibms",
                    name: "IBMS",
                    component: () => import("../views/services/ibms.vue"),
               },
               {
                    path: "/solutions/ites",
                    name: "ITES",
                    component: () => import("../views/services/ites.vue"),
               },
               {
                    path: "/solutions/das",
                    name: "DAS",
                    component: () => import("../views/services/das.vue"),
               },

               {
                    path: "/certifications",
                    name: "CERTIFICATION",
                    component: () => import("../views/Certifications.vue"),
               },

               {
                    path: "/about",
                    name: "ABOUT",
                    component: () => import("../views/About.vue"),
               },
               {
                    path: "/contact",
                    name: "CONTACT",
                    component: () => import("../views/Contact.vue"),
               },

               {
                    path: "/projects",
                    name: "PROJECTS",
                    component: () => import("../views/Projects.vue"),
               },
               {
                    path: "/career",
                    name: "CAREER",
                    component: () => import("../views/Careers.vue"),
               },
               {
                    path: "/industries",
                    name: "INDUSTRIES",
                    component: () => import("../views/Industries.vue"),
               },

               {
                    path: "/industries/healthcare",
                    name: "HEALTHCARE",
                    component: () => import("../views/industry/Healthcare.vue"),
               },
               {
                    path: "/industries/mall&infra",
                    name: "MALL",
                    component: () => import("../views/industry/Mall.vue"),
               },
               {
                    path: "/industries/finance",
                    name: "FINANCE",
                    component: () => import("../views/industry/Finance.vue"),
               },
               {
                    path: "/industries/hospitality",
                    name: "HOSPITALITY",
                    component: () => import("../views/industry/Hospitality.vue"),
               },
               {
                    path: "/projects/hyatt",
                    name: "HYATT",
                    component: () => import("../views/projects/Hyatt.vue"),
               },
               //  {
               //       path: '/projects/finance',
               //       name: 'HEALTHCARE',
               //       component: () => import('../views/industry/Finance.vue'),
               //  },

               {
                    path: "/comingsoon",
                    name: "COMINGSOON",
                    component: () => import("../views/Comingsoon.vue"),
               },

               {
                    path: "/test",
                    name: "TEST",
                    component: () => import("../views/test.vue"),
               },
               {
                    path: "*",
                    name: "NOTFOUND",
                    component: () => import("../views/404.vue"),
               },
          ],
     },
];

const router = new VueRouter({
     mode: "history",
     base: process.env.BASE_URL,
     routes,
     scrollBehavior(to, from, savedPosition) {
          if (to.params.scroll) {
               // return {
               //      selector: to.params.scroll,
               // };
               return savedPosition;
          }
          if (savedPosition) {
               return savedPosition;
          } else {
               return { x: 0, y: 0 };
          }
     },
});

export default router;
